import React, { useContext } from 'react';

export interface MainSupportContextInterface {
    treeProvider: Record<string, ItemProviderStruct>;
    path: string[];
    titlePath: string | 'invalid route';
    title: string;
    isLoadingTree: boolean;
    emailUser: string;
    alertType: string;
    setTreeProvider: React.Dispatch<React.SetStateAction<Record<string, ItemProviderStruct>>>;
    setPath: React.Dispatch<React.SetStateAction<string[]>>;
    setTitlePath: React.Dispatch<React.SetStateAction<string | 'invalid route'>>;
    setTitle: React.Dispatch<React.SetStateAction<string>>;
    setEmailUser: React.Dispatch<React.SetStateAction<string>>;
    setAlertType: React.Dispatch<React.SetStateAction<string>>;
    setIsLoadingTree: React.Dispatch<React.SetStateAction<boolean>>;
    findTreeId: (id: string) => string;
    updateElements: (login?: string) => void;
}

export const MainSupportContext = React.createContext<MainSupportContextInterface>({
    treeProvider: {},
    path: [],
    titlePath: '',
    title: '',
    isLoadingTree: false,
    emailUser: '',
    alertType: '',
    setTreeProvider: () => {
        /* Nothing to do here*/
    },
    setEmailUser: () => {
        /* Nothing to do here*/
    },
    setTitlePath: () => {
        /* Nothing to do here*/
    },
    setTitle: () => {
        /* Nothing to do here*/
    },
    setAlertType: () => {
        /* Nothing to do here*/
    },
    setIsLoadingTree: () => {
        /* Nothing to do here*/
    },
    setPath: () => {
        /* Nothing to do here*/
    },
    findTreeId: () => {
        return '';
        /* Nothing to do here*/
    },
    updateElements: () => {
        /* Nothing to do here*/
    },
});

export const useMainSupportContext = (): MainSupportContextInterface => {
    const store = useContext(MainSupportContext);
    return store;
};

export interface ItemProviderStruct {
    id: string;
    title: string;
    subtitle: string;
    type: number;
    login: boolean;
    suggestions: string[];
    showForm: boolean;
    showAlert: boolean;
    parent: string;
    children: string[];
}
