/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import 'components/alert/alert.scss';

export interface AlertProps {
    showAlert: boolean;
}

const Alert: React.FC<AlertProps> = (props) => {
    // State
    function onShowAlert() {
        if (props.showAlert) {
            return false;
        } else return true;
    }

    return (
        <div className="response-success" hidden={onShowAlert()}>
            <img className="response-image" src="logoSuccess.png" alt="" />
            <h2 className="title-response-success">¡Enhorabuena!</h2>
            <span className="body-response-success">
                Ya puedes volver a la aplicación. Presiona la <b>X</b> para volver a la misma.
            </span>
        </div>
    );
};

export default Alert;
