import React, { useEffect, useState } from 'react';
import { AddItemModalController, Suggestion } from './interfaces';
import { useForm } from 'antd/lib/form/Form';
import { useSupportControlPanelContext } from '../../context/support-control-panel.context';
import { CreateElementInput } from 'services/capture-support/capture-support.service';

const defaultInput: CreateElementInput = {
    title: '',
    subtitle: '',
    type: 0,
    login: false,
    suggestions: [],
    showForm: false,
    showAlert: false,
    parent: '',
};

export const useAddItemModalController = (
    editModal,
): /* <--Dependency Injections  like services hooks */
AddItemModalController => {
    const {
        isModalAddVisible,
        isModalEditVisible,
        setIsModalAddVisible,
        inputModalAdd,
        setInputModalAdd,
        createChild,
        updateChild,
        setIsModalEditVisible,
        validate,
        setAlertError,
    } = useSupportControlPanelContext();
    const [form] = useForm();
    /* State */
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

    /* Listeners */
    useEffect(() => {
        setInputModalAdd({ ...inputModalAdd, suggestions: suggestions.map((s) => s.value) });
    }, [suggestions]);
    useEffect(() => {
        form.resetFields();
        if (editModal) {
            setSuggestions(
                inputModalAdd.suggestions.map((s, i) => {
                    return { value: s, pos: i, id: i };
                }),
            );
        }
    }, [isModalAddVisible]);

    /* View Events */
    const onCreateChild = () => {
        const errors = validate();
        console.log({ errors });

        if (errors.length) {
            errors.forEach((err) => {
                setAlertError(err);
            });
            return;
        }
        setSuggestions([]);
        createChild({ ...inputModalAdd });
        setInputModalAdd(defaultInput);
        setIsModalAddVisible(false);
    };
    const onEditChild = () => {
        setSuggestions([]);
        updateChild();
        setIsModalEditVisible(false);
    };
    const onCancelAddModal = () => {
        form.resetFields();
        setSuggestions([]);
        setInputModalAdd(defaultInput);
        setIsModalAddVisible(false);
        setIsModalEditVisible(false);
    };
    const onTypeSelect = (id) => {
        setInputModalAdd({ ...inputModalAdd, type: id, title: '' });
    };
    const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputModalAdd({ ...inputModalAdd, title: e.target.value });
    };
    const onAddSuggestion = () => {
        const newSug = [...suggestions];
        newSug.push({
            id: suggestions.length + 1,
            pos: suggestions.length,
            value: '',
        });
        setSuggestions(newSug);
    };
    const onChangeSuggestion = (id, value) => {
        const index = suggestions.findIndex((s) => s.id === id);
        const newSug = [...suggestions];
        newSug[index].value = value;
        setSuggestions(newSug);
    };
    const onMoveSuggestion = (id, to) => {
        const index = suggestions.findIndex((s) => s.id === id);
        const newSug = [...suggestions];
        switch (to) {
            case 'up':
                if (index > 0) {
                    newSug[index].pos -= 1;
                    newSug[index - 1].pos += 1;
                }
                break;
            case 'down':
                if (index + 1 < suggestions.length) {
                    newSug[index].pos += 1;
                    newSug[index + 1].pos -= 1;
                }
                break;
            default:
                break;
        }
        setSuggestions(newSug.sort((a, b) => a.pos - b.pos));
    };
    const onRemoveSuggestion = (id) => {
        const newSug = suggestions
            .filter((s) => s.id !== id)
            .map((e, i) => {
                e.id = i + 1;
                e.pos = i;
                return e;
            });
        setSuggestions(newSug);
    };

    /* Private Methods */

    // Return state and events
    return {
        form,
        isModalAddVisible,
        isModalEditVisible,
        inputModalAdd,
        suggestions,
        onCreateChild,
        onEditChild,
        onCancelAddModal,
        onTypeSelect,
        onTitleChange,
        onAddSuggestion,
        onChangeSuggestion,
        onMoveSuggestion,
        onRemoveSuggestion,
    };
};
