import { useState, useEffect } from 'react';
import { MainSupportController } from 'fragments/main-support/interfaces';
import { ItemProviderStruct, useMainSupportContext } from './context/main-support.context';
import { useSearchParams } from 'react-router-dom';

const defaultSuggestion: ItemProviderStruct = {
    id: 'sug',
    title: 'Sugerencias',
    subtitle: 'SUGGESTIONS',
    type: 1,
    login: false,
    suggestions: [],
    showForm: false,
    showAlert: false,
    parent: '',
    children: [],
};
export const useMainSupportController =
    (): /* <--Dependency Injections  like services hooks */ MainSupportController => {
        const {
            path,
            title,
            isLoadingTree,
            setTitle,
            setTitlePath,
            setPath,
            treeProvider,
            emailUser,
            alertType,
            setAlertType,
            setEmailUser,
            findTreeId,
            updateElements,
        } = useMainSupportContext();

        const [searchParams] = useSearchParams();
        const loginParam = searchParams.get('login') || 'false';

        /* State */
        const [bodyTicket, setBodyTicket] = useState('');
        const [treeMainSupport, setTreeMainSupport] = useState<Record<string, ItemProviderStruct>>({});
        const [currentQuestion, setCurrentQuestion] = useState<ItemProviderStruct>({
            id: '',
            title: 'Cargando...',
            subtitle: 'Preguntas Frecuentes',
            type: 0,
            login: false,
            suggestions: [],
            showForm: false,
            showAlert: false,
            parent: '',
            children: [],
        });

        useEffect(() => {
            updateElements(loginParam);
        }, []);

        useEffect(() => {
            if (!Object.values(treeProvider).length) return;
            const initialTree = providerToMainSupport(treeProvider);

            if (!initialTree) return console.log('Error: convert Provider to Main');
            setTreeMainSupport(initialTree);
            setCurrentQuestion(initialTree['main']);
            const currQuestion = getQuestionByPath();
            if (currQuestion !== 'invalid route') {
                setCurrentQuestion(currQuestion);
                if (currQuestion.type === 0 || currQuestion.type === 1) {
                    setAlertType('');
                }
            }
        }, [treeProvider]);

        useEffect(() => {
            setTitlePath(getTitlePath());
            setTitle(currentQuestion.type === 0 ? currentQuestion.title : title);
        }, [currentQuestion]);

        useEffect(() => {
            //console.log({ treeMainSupport });
        }, [treeMainSupport]);

        useEffect(() => {
            const currQuestion = getQuestionByPath();
            if (currQuestion !== 'invalid route') {
                setCurrentQuestion(currQuestion);
                if (currQuestion.type === 0 || currQuestion.type === 1) {
                    setAlertType('');
                }
            }
        }, [path]);

        /* Public Methods */
        const onBackBtnClick = () => {
            onChildrenBack();
        };

        const onTitleItemClick = (id: string) => {
            onChildrenSelected(id);
        };

        const onChildrenSelected = (id: string) => {
            setPath([...path, id]);
            if (currentQuestion.type === 0) {
                setEmailUser('');
            }
        };
        const onChildrenBack = () => {
            const newPath = [...path];
            newPath.pop();
            setPath(newPath);
        };
        const getQuestionByPath = () => {
            const question =
                treeProvider[findTreeId(path[path.length - 1])] ||
                treeProvider['main' + (loginParam === 'true' ? 0 : 1)];
            if (!question) return 'invalid route';
            return question;
        };

        /* Private Methods */
        const providerToMainSupport = (tree: Record<string, ItemProviderStruct>) => {
            try {
                const entries = Object.entries(tree);
                const newTree = groupSuggestions(entries, tree);

                return newTree;
            } catch (err) {
                console.log('err: ', err);
            }
        };
        const groupSuggestions = (
            entries: [string, ItemProviderStruct][],
            tree: Record<string, ItemProviderStruct>,
        ) => {
            let nextIndex = 0;
            let newTree = {};
            let suggestionsCollection: Record<string, ItemProviderStruct> = {};

            for (const e of entries) {
                if (e[1].type === 1) {
                    const sugCollector = suggestionsCollection[findTreeId(e[1].parent)] || defaultSuggestion;
                    suggestionsCollection = {
                        ...suggestionsCollection,
                        [findTreeId(e[1].parent)]: {
                            ...sugCollector,
                            id: nextIndex.toString(),
                            suggestions: [...sugCollector.suggestions, e[1].id],
                        },
                    };
                    nextIndex++;
                } else {
                    newTree = { ...newTree, [e[0]]: e[1] };
                    continue;
                }
            }
            const sugEntries = Object.entries(suggestionsCollection).map((sugGroup) => {
                const children = tree[sugGroup[0]]?.children;
                if (children) {
                    sugGroup[1].suggestions = sugGroup[1].suggestions
                        .sort((a, b) => children.findIndex((x) => x === a) - children.findIndex((x) => x === b))
                        .map((s) => tree[findTreeId(s)]?.title);
                }
                return sugGroup;
            });
            for (const e of sugEntries) {
                let firstFounded = false;
                const filtered = newTree[e[0]]?.children.reduce((acc, ch) => {
                    if (tree[findTreeId(ch)]?.type !== 1) return [...acc, ch];
                    if (!firstFounded) {
                        firstFounded = true;
                        return [...acc, e[1].id];
                    }
                    return acc;
                }, []);
                newTree[e[0]].children = [...filtered];
                newTree['item' + e[1].id] = e[1];
            }
            return newTree;
        };
        const getTitlePath = () => {
            const titleByPath: string[] = path.map((id) => treeProvider[findTreeId(id)]?.title || 'invalid');
            if (titleByPath.some((t) => t === 'invalid')) return 'invalid route';
            return titleByPath.join(' > ');
        };

        // Return state and events
        return {
            treeProvider,
            treeMainSupport,
            path,
            currentQuestion,
            bodyTicket,
            title,
            emailUser,
            alertType,
            isLoadingTree,
            setBodyTicket,
            setEmailUser,
            onChildrenSelected,
            onChildrenBack,
            onBackBtnClick,
            onTitleItemClick,
        };
    };
