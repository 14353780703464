import React from 'react';
import 'fragments/main/main.scss';
import { MainFragmentProps } from 'fragments/main/interfaces';
import { Layout } from 'antd';
import { AppRoutes } from 'fragments/main/routes/app-routes';
const { Content, Footer } = Layout;

export const MainFragment: React.FC<MainFragmentProps> = () => {
    // Render
    return (
        <div className={'main'}>
            <Layout style={{ minHeight: '100vh' }}>
                <Content className={'content-root'}>
                    <AppRoutes />
                </Content>
                <Footer className={'footer-root'}>
                    <a href="https://accefy.io" className={'footer-link'}>
                        Accefy.io
                    </a>
                    ©
                </Footer>
            </Layout>
        </div>
    );
};
