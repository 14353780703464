import React, { useContext } from 'react';
import { CreateElementInput } from 'services/capture-support/capture-support.service';
import { ItemProviderStruct } from 'fragments/main-support/context/main-support.context';

export interface SupportControlPanelContextInterface {
    treeControlItems: Record<string, ItemProviderStruct>;
    isModalAddVisible: boolean;
    isModalEditVisible: boolean;
    parentId: string;
    alertError: string;
    inputModalAdd: CreateElementInput;
    setInputModalAdd: React.Dispatch<React.SetStateAction<CreateElementInput>>;
    setIsModalAddVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsModalEditVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setParentId: React.Dispatch<React.SetStateAction<string>>;
    createChild: (input: CreateElementInput) => void;
    updateChild: () => void;
    removeChild: (id: string) => void;
    moveChild: (item: ItemProviderStruct, oldPos: number, newPos: number) => void;
    validate: () => string[];
    setAlertError: React.Dispatch<React.SetStateAction<string>>;
}

export const SupportControlPanelContext = React.createContext<SupportControlPanelContextInterface>({
    treeControlItems: {},
    isModalAddVisible: false,
    isModalEditVisible: false,
    parentId: '',
    alertError: '',
    inputModalAdd: {
        title: '',
        subtitle: '',
        type: 0,
        login: false,
        suggestions: [],
        showForm: false,
        showAlert: false,
        parent: '',
    },
    setInputModalAdd: () => {
        /* Nothing to do here*/
    },
    setIsModalAddVisible: () => {
        /* Nothing to do here*/
    },
    setIsModalEditVisible: () => {
        /* Nothing to do here*/
    },
    setParentId: () => {
        /* Nothing to do here*/
    },
    createChild: () => {
        /* Nothing to do here*/
    },
    updateChild: () => {
        /* Nothing to do here*/
    },
    removeChild: () => {
        /* Nothing to do here*/
    },
    moveChild: () => {
        /* Nothing to do here*/
    },
    validate: () => {
        return [];
        /* Nothing to do here*/
    },
    setAlertError: () => {
        /* Nothing to do here*/
    },
});

export const useSupportControlPanelContext = (): SupportControlPanelContextInterface => {
    const store = useContext(SupportControlPanelContext);
    return store;
};
