import React from 'react';
import 'fragments/support-control-panel/fragments/add-item-modal/add-item-modal.scss';
import { AddItemModalFragmentProps } from './interfaces';
import { useAddItemModalController } from './add-item-modal.controller';
import { Button, Descriptions, Form, Input, Modal, Select, Typography } from 'antd';
import {
    PlusOutlined,
    QuestionCircleOutlined,
    UnorderedListOutlined,
    FileSearchOutlined,
    MessageOutlined,
    MinusCircleOutlined,
    UpCircleOutlined,
    DownCircleOutlined,
} from '@ant-design/icons';
import { useTranslator } from '../../../../tools/view-hooks/translator-hook';
const { Text, Title } = Typography;
const { Option } = Select;

export const AddItemModalFragment: React.FC<AddItemModalFragmentProps> = (props) => {
    const { useController = useAddItemModalController, editModal } = props;
    const controller = useController(editModal);
    const { translate } = useTranslator();

    // Render
    return (
        <Modal
            open={editModal ? controller.isModalEditVisible : controller.isModalAddVisible}
            destroyOnClose={true}
            title={
                <Title level={4} style={{ padding: 0 }}>
                    {editModal ? translate({ key: 'Editar Item' }) : translate({ key: 'Agregar Item' })}
                </Title>
            }
            className={'keys-filter-modal'}
            closable={false}
            centered
            footer={[
                <Button key="back" className={'btn-cancel'} onClick={controller.onCancelAddModal}>
                    {translate({ key: 'general.cancel' })}
                </Button>,
                <Button
                    form="filrter-keys-form"
                    key="submit"
                    type="primary"
                    className={'button primary'}
                    htmlType="submit"
                >
                    {editModal ? translate({ key: 'general.edit' }) : translate({ key: 'general.add' })}
                </Button>,
            ]}
        >
            <Form
                id="filrter-keys-form"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                layout="horizontal"
                size="middle"
                className={'form'}
                form={controller.form}
                onFinish={editModal ? controller.onEditChild : controller.onCreateChild}
            >
                {!editModal && (
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                Tipo de item
                            </Text>
                        }
                        name="type"
                        initialValue={controller.inputModalAdd.type}
                    >
                        <Select
                            placeholder={'Seleccionar'}
                            defaultValue={0}
                            onChange={controller.onTypeSelect}
                            style={{ width: '100%' }}
                        >
                            <Option value={0}>
                                <QuestionCircleOutlined /> Pregunta
                            </Option>
                            <Option value={1}>
                                <UnorderedListOutlined /> Sugerencias
                            </Option>
                            <Option value={2}>
                                <MessageOutlined /> Pregunta Ticket
                            </Option>
                            <Option value={3}>
                                <FileSearchOutlined /> Validacion de Usuario
                            </Option>
                        </Select>
                    </Form.Item>
                )}
                {(controller.inputModalAdd.type === 0 || controller.inputModalAdd.type === 1) && (
                    <Form.Item
                        label={
                            <Text type="secondary" strong>
                                {controller.inputModalAdd.type === 1 ? 'Sugerencia' : 'Título'}
                            </Text>
                        }
                        name="title"
                        initialValue={controller.inputModalAdd.title}
                    >
                        <Input
                            required
                            style={{ width: '100%' }}
                            onChange={controller.onTitleChange}
                            defaultValue={controller.inputModalAdd.title}
                            placeholder={
                                controller.inputModalAdd.type === 1
                                    ? 'Ej: Intentalo mas tarde'
                                    : 'Ej.: ¿En qué podemos ayudarte?'
                            }
                        />
                    </Form.Item>
                )}

                {false && (
                    <Form.Item
                        name="suggestions"
                        rules={[
                            {
                                validator: async () => {
                                    if (!controller.suggestions || controller.suggestions.length < 1) {
                                        return Promise.reject(new Error('Debes escribir al menos una sugerencia'));
                                    }
                                },
                            },
                        ]}
                    >
                        <Descriptions title={'Lista de Sugerencias'}></Descriptions>
                        {controller.suggestions.map((item) => (
                            <>
                                <Input
                                    required
                                    defaultValue={item.value.length || item.value}
                                    //value={item.value}
                                    onChange={(e) => controller.onChangeSuggestion(item.id, e.target.value)}
                                ></Input>
                                {controller.suggestions.length > 1 ? (
                                    <span>
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => {
                                                controller.onRemoveSuggestion(item.id);
                                            }}
                                        />
                                        <UpCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => {
                                                controller.onMoveSuggestion(item.id, 'up');
                                            }}
                                        />
                                        <DownCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => {
                                                controller.onMoveSuggestion(item.id, 'down');
                                            }}
                                        />
                                    </span>
                                ) : null}
                            </>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => {
                                    controller.onAddSuggestion();
                                }}
                                style={{ width: '60%', marginTop: '0.5rem' }}
                                icon={<PlusOutlined />}
                            >
                                Agregar Sugerencia
                            </Button>
                        </Form.Item>
                    </Form.Item>
                )}
            </Form>
        </Modal>
    );
};
