import React, { ReactNode } from 'react';
import 'fragments/support-control-panel/support-control-panel.scss';
import { SupportControlPanelFragmentProps } from 'fragments/support-control-panel/interfaces';
import { AddItemModalFragment } from './fragments/add-item-modal/add-item-modal.fragment';
import { useSupportControlPanelController } from 'fragments/support-control-panel/support-control-panel.controller';
import { Button, Col, Row, Spin, Tooltip, Tree, Typography } from 'antd';
import {
    PlusOutlined,
    DeleteOutlined,
    EditOutlined,
    QuestionCircleOutlined,
    UnorderedListOutlined,
    FileSearchOutlined,
    MessageOutlined,
    UpCircleOutlined,
    DownCircleOutlined,
    CheckCircleOutlined,
    DatabaseOutlined,
} from '@ant-design/icons';
import { ItemProviderStruct, useMainSupportContext } from 'fragments/main-support/context/main-support.context';
import { Key } from 'antd/lib/table/interface';
const { Text, Title } = Typography;
const { TreeNode } = Tree;

export const SupportControlPanelFragment: React.FC<SupportControlPanelFragmentProps> = (props) => {
    const { useController = useSupportControlPanelController } = props;
    const controller = useController();
    const { findTreeId } = useMainSupportContext();

    const generateNode = (data: ItemProviderStruct) => {
        const initialData = { ...data };
        let title: ReactNode = 'Sin Titulo';
        const buttonsEnabled = {
            plus: false,
            edit: false,
            delete: true,
            move: false,
        };
        buttonsEnabled.move = controller.treeControlItems[findTreeId(data.parent)]?.children.length > 1;
        const titlesByType = [
            data.title,
            data.title,
            'Pregunta Ticket',
            'Validacion de usuario',
            'Alerta de Validacion',
            '',
            '',
            '',
            'Pregunta Ticket',
            '',
            'Raíz',
        ];
        title = titlesByType[data.type];
        let icon: ReactNode = <></>;
        switch (data.type) {
            case 0:
                icon = <QuestionCircleOutlined style={{ color: 'violet' }} />;
                break;
            case 1:
                icon = <UnorderedListOutlined style={{ color: 'blue' }} />;
                break;
            case 2:
                icon = <MessageOutlined style={{ color: 'green' }} />;
                break;
            case 3:
                icon = <FileSearchOutlined style={{ color: 'aquamarine' }} />;
                break;
            case 8:
                icon = <MessageOutlined style={{ color: 'green' }} />;
                break;
        }

        switch (data.type) {
            case 0:
                buttonsEnabled.plus = true;
                buttonsEnabled.edit = true;
                break;
            case 1:
                buttonsEnabled.edit = true;
                break;
            case 2:
                if (data.showForm) {
                    title = 'Formulario Ticket';
                    buttonsEnabled.delete = false;
                }
                break;
            case 3:
                buttonsEnabled.plus = true;
                break;
            case 4:
                buttonsEnabled.delete = false;
                break;
            case 5:
                if (data.showAlert) {
                    title = 'Esto no deberia verse, por favor reportar';
                } else {
                    title = 'error';
                    buttonsEnabled.delete = false;
                }
                break;
            case 8: // group
                data =
                    data.children
                        .map((id) => controller.treeControlItems[findTreeId(id)])
                        .find((ch) => ch?.type === 2) || data;
                data.type = 8;
                break;
            case 10: // group
                buttonsEnabled.plus = false;
                buttonsEnabled.edit = false;
                buttonsEnabled.delete = false;
                break;
            default:
                break;
        }

        if (findTreeId(data.id) === 'main0' || findTreeId(data.id) === 'main1') {
            title = title + ` (${data.login ? '' : 'No '}Login)`;
            buttonsEnabled.move = false;
            buttonsEnabled.delete = false;
            icon = <DatabaseOutlined />;
        }

        const NodeButtons: React.ReactNode = (
            <>
                {buttonsEnabled.plus && (
                    <Tooltip mouseEnterDelay={1} mouseLeaveDelay={0.05} title="Añadir Item">
                        <Button
                            className={'support-control-panel-tree-button'}
                            icon={<PlusOutlined />}
                            type={'text'}
                            onClick={() => controller.onAddChildBtnPressed(data.id)}
                        />
                    </Tooltip>
                )}
                {buttonsEnabled.edit && (
                    <Tooltip mouseEnterDelay={1} mouseLeaveDelay={0.05} title="Editar Item">
                        <Button
                            className={'support-control-panel-tree-button'}
                            icon={<EditOutlined />}
                            type={'text'}
                            onClick={() => controller.onEditChildBtnPressed(data)}
                        />
                    </Tooltip>
                )}
                {buttonsEnabled.move && (
                    <>
                        <Tooltip mouseEnterDelay={1} mouseLeaveDelay={0.05} title="Mover Arriba">
                            <Button
                                className={'support-control-panel-tree-button'}
                                icon={<UpCircleOutlined />}
                                type={'text'}
                                onClick={() =>
                                    controller.onMoveChildBtnPressed(data.type === 8 ? initialData : data, 'up')
                                }
                            />
                        </Tooltip>
                        <Tooltip mouseEnterDelay={1} mouseLeaveDelay={0.05} title="Mover Abajo">
                            <Button
                                className={'support-control-panel-tree-button'}
                                icon={<DownCircleOutlined />}
                                type={'text'}
                                onClick={() =>
                                    controller.onMoveChildBtnPressed(data.type === 8 ? initialData : data, 'down')
                                }
                            />
                        </Tooltip>
                    </>
                )}
                {buttonsEnabled.delete && findTreeId(data.id) !== 'main' && (
                    <Tooltip mouseEnterDelay={1} mouseLeaveDelay={0.05} title="Eliminar Item">
                        <Button
                            className={'support-control-panel-tree-button'}
                            icon={<DeleteOutlined />}
                            type={'text'}
                            onClick={() => controller.onRemoveChildBtnPressed(data)}
                        />
                    </Tooltip>
                )}
            </>
        );
        const node: React.ReactNode = (
            <TreeNode
                id={data.id}
                key={data.id as Key}
                title={
                    <Text className={'support-control-panel-tree-title'}>
                        {icon}
                        <> {title} </>
                        {NodeButtons}
                    </Text>
                }
            />
        );
        return node;
    };
    const buildTreeData = (id: string) => {
        const propName = findTreeId(id);
        const mainTree: ItemProviderStruct = controller.treeControlItems[propName];
        if (mainTree) {
            let node = generateNode(mainTree);
            if (mainTree.children.length) {
                const childrenContent = mainTree.children.map((ch) => buildTreeData(ch));
                if (mainTree.type === 8) {
                    node = <TreeNode {...node.props}></TreeNode>;
                } else {
                    node = <TreeNode {...node.props}>{childrenContent}</TreeNode>;
                }
                return node;
            } else {
                return node;
            }
        }
    };

    // Render
    return (
        <div className={'support-control-panel'}>
            {controller.contextHolder}
            <Row justify={'space-between'} align={'middle'}>
                <Title>
                    Panel de Control
                    {!controller.treeControlItems['root'] || controller.isLoadingTree ? (
                        <Spin style={{ marginLeft: '1rem' }} />
                    ) : (
                        <CheckCircleOutlined
                            style={{ marginLeft: '1rem', color: 'green', fontSize: '1.5rem', opacity: '80' }}
                        />
                    )}
                </Title>
                <Col>
                    <Button className={'support-control-panel-expand'} onClick={controller.onExpandAllBtnPressed}>
                        Expandir todo
                    </Button>
                    <Button className={'support-control-panel-expand'} onClick={controller.onCollapseBtnPressed}>
                        Contraer todo
                    </Button>
                </Col>
            </Row>
            {!!controller.treeControlItems['root'] && (
                <Tree
                    className={'support-control-panel-tree'}
                    showIcon
                    onExpand={controller.onExpandBtnPressed}
                    expandedKeys={controller.expanded}
                    fieldNames={{ key: 'hola' }}
                >
                    {buildTreeData('root')}
                </Tree>
            )}

            {controller.isModalAddVisible && <AddItemModalFragment />}
            {controller.isModalEditVisible && <AddItemModalFragment editModal />}
        </div>
    );
};
