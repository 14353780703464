import React, { ReactNode } from 'react';
import 'fragments/main-support/main-support.scss';
import { MainSupportFragmentProps } from 'fragments/main-support/interfaces';
import { useMainSupportController } from 'fragments/main-support/main-support.controller';
import { List, Button } from 'antd';
import Type1Support from 'components/type-1-support/type-1-support.component';
import ValidateUser from 'components/validate-user/validate-user.component';
import NewTicket from 'components/new-ticket/new-ticket.component';
import { CaretRightOutlined, LeftOutlined } from '@ant-design/icons';
import Alert from 'components/alert/alert.component';
import AlertValidateUser from 'components/alert-validate-user/alert-validate-user.component';
import { useMainSupportContext } from './context/main-support.context';

export const MainSupportFragment: React.FC<MainSupportFragmentProps> = (props) => {
    const { useController = useMainSupportController } = props;
    const controller = useController();
    const { findTreeId } = useMainSupportContext();

    // Renders
    return (
        <div className={'background'}>
            <Button
                icon={<LeftOutlined />}
                className="btnBack"
                onClick={controller.onBackBtnClick}
                hidden={!controller.path.length ? true : false}
            >
                Volver
            </Button>
            <div className="main-support">
                <b>
                    <h2 className="title" hidden={controller.currentQuestion.type != 0}>
                        {controller.title}
                    </h2>
                </b>
                <List
                    itemLayout="vertical"
                    size="default"
                    className="list-questions"
                    loading={controller.isLoadingTree}
                >
                    {(() => {
                        return controller.currentQuestion.children
                            .map((id) => controller.treeMainSupport[findTreeId(id)])
                            .map((item) => {
                                let listItemContent: ReactNode = <></>;
                                if (!item) return; //probalemente porque es type 5, estos se eliminan del arbol
                                switch (item.type) {
                                    case 0:
                                        listItemContent = (
                                            <Button
                                                value={item.id}
                                                className="list-questions-items"
                                                onClick={() => controller.onTitleItemClick(item.id)}
                                                hidden={item.title === '' || controller.currentQuestion.type !== 0}
                                            >
                                                {item.title}
                                                <CaretRightOutlined className="icon-right-outlined" />
                                            </Button>
                                        );
                                        break;
                                    case 1:
                                        listItemContent = (
                                            <Type1Support
                                                title={item.title}
                                                suggestions={item.suggestions}
                                            ></Type1Support>
                                        );
                                        break;
                                    case 2:
                                        listItemContent = (
                                            <NewTicket
                                                useController={props.useController}
                                                email={controller.emailUser}
                                                onSelect={controller.onChildrenSelected}
                                                showForm={item.showForm}
                                                currentQuestion={controller.currentQuestion}
                                                id={item.id}
                                            ></NewTicket>
                                        );
                                        break;
                                    case 3:
                                        listItemContent = (
                                            <ValidateUser
                                                id={item.id}
                                                onSelect={controller.onTitleItemClick}
                                            ></ValidateUser>
                                        );
                                        break;
                                    case 4:
                                        listItemContent = (
                                            <AlertValidateUser
                                                type={controller.alertType}
                                                onChildrenBack={controller.onChildrenBack}
                                            />
                                        );
                                        break;
                                    case 5:
                                        listItemContent = (
                                            <Alert showAlert={controller.currentQuestion.showAlert}></Alert>
                                        );
                                        break;
                                    default:
                                        listItemContent = null;
                                        break;
                                }
                                return <List.Item key={item.id}>{listItemContent}</List.Item>;
                            });
                    })()}
                </List>
            </div>
        </div>
    );
};
