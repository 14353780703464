import {
    CaptureSupport,
    changeElementsResponse,
    CheckUserRequest,
    CheckUserResponse,
    CreateElementInput,
    CreateTicketInput,
    UpdateElementInput,
} from 'services/capture-support/capture-support.service';
import axios from 'axios';
import { Question } from 'fragments/main-support/interfaces';

export const useAPICaptureSupport = (): CaptureSupport => {
    const getElements = async (): Promise<Question[]> => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/support/db`);

            return response.data as Question[];
        } catch (error) {
            throw error;
        }
    };
    const getAllElements = async (login: string): Promise<[]> => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL + `/support/db/all?type=${login}`);

            return response.data;
        } catch (error) {
            throw error;
        }
    };
    const insertElement = async (input: CreateElementInput): Promise<changeElementsResponse> => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + `/support/db`, input);

            return response.data;
        } catch (error) {
            throw error;
        }
    };
    const modifyElement = async (id: string, input: UpdateElementInput): Promise<changeElementsResponse> => {
        try {
            const response = await axios.put(process.env.REACT_APP_API_BASE_URL + `/support/db/${id}`, input);

            return response.data;
        } catch (error) {
            throw error;
        }
    };
    const removeElement = async (id: string): Promise<changeElementsResponse> => {
        try {
            const response = await axios.delete(process.env.REACT_APP_API_BASE_URL + `/support/db/${id}`);

            return response.data;
        } catch (error) {
            throw error;
        }
    };

    const createTicket = async (input: CreateTicketInput): Promise<unknown> => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + `/newhubspotticket`, input, {});

            return response.data as Record<string, unknown>;
        } catch (error) {
            throw error;
        }
    };

    const checkUser = async (input: CheckUserRequest): Promise<CheckUserResponse> => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL + `/support/check/email`, input, {});

            return response.data;
        } catch (error) {
            throw error;
        }
    };

    return {
        getElements,
        getAllElements,
        insertElement,
        modifyElement,
        removeElement,
        createTicket,
        checkUser,
    };
};
