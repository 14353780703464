import React from 'react';
import 'components/type-1-support/type-1-support.scss';
import { List } from 'antd';

export interface Type1SupportProps {
    title: string;
    suggestions: string[];
}

const Type1Support: React.FC<Type1SupportProps> = (props) => {
    // State
    return (
        <div>
            <div className="type-1-support">
                <h2 className="title-suggestions">{props.title}</h2>
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={props.suggestions}
                    renderItem={(item) => (
                        <List.Item className="type1-items">
                            <ul>
                                <li>
                                    <h4 hidden={props.suggestions.length === 0}> {item} </h4>
                                </li>
                            </ul>
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
};

export default Type1Support;
