import { message, Modal } from 'antd';
import { ItemProviderStruct, useMainSupportContext } from 'fragments/main-support/context/main-support.context';
import { SupportControlPanelController } from 'fragments/support-control-panel/interfaces';
import { useEffect, useState } from 'react';
import { useSupportControlPanelContext } from './context/support-control-panel.context';
import { Key } from 'antd/lib/table/interface';

export const useSupportControlPanelController = (): /* <--Dependency Injections  like services hooks */
SupportControlPanelController => {
    const { isLoadingTree, treeProvider, findTreeId } = useMainSupportContext();
    const {
        setIsModalAddVisible,
        isModalAddVisible,
        isModalEditVisible,
        removeChild,
        setParentId,
        moveChild,
        setInputModalAdd,
        inputModalAdd,
        setIsModalEditVisible,
        treeControlItems,
        alertError,
        setAlertError,
    } = useSupportControlPanelContext();
    /* State */
    const [expanded, setExpanded] = useState<Key[]>([]);
    const [allKeys, setAllKeys] = useState<Key[]>([]);
    const [messageApi, contextHolder] = message.useMessage();

    /* Listeners */
    useEffect(() => {
        if (alertError !== '') sendAlert(alertError);
        setAlertError('');
    }, [alertError]);

    useEffect(() => {
        setAllKeys(generateExpectedKeys());
        !expanded.length && setExpanded(['0-0']);
    }, [treeControlItems]);

    /* ================== View Events ================== */

    const onAddChildBtnPressed = (id: string) => {
        setParentId(id);
        setIsModalAddVisible(true);
    };

    const onEditChildBtnPressed = (item: ItemProviderStruct) => {
        const data = {
            ...inputModalAdd,
            title: item.title,
            type: item.type,
            suggestions: item.suggestions,
        };
        setParentId(item.id);
        setInputModalAdd(data);
        setIsModalEditVisible(true);
    };

    const onRemoveChildBtnPressed = (item: ItemProviderStruct) => {
        setParentId(item.id);
        Modal.confirm({
            title: `¿Seguro que deseas eliminar el elemento${' "' + item.title + '"'}?${
                item.children.length ? ' Se eliminaran tambien los elementos que contenga.' : ''
            }`,
            onOk: () => {
                removeChild(item.id);
            },
            okText: 'Eliminar',
        });
    };

    const onMoveChildBtnPressed = (item: ItemProviderStruct, dir: 'up' | 'down') => {
        if (item.type === 2 || item.type === 5) item = treeControlItems[findTreeId(item.parent)];
        const parentItem: ItemProviderStruct = treeControlItems[findTreeId(item.parent)];
        if (!parentItem) return console.log('err: no se encontró el padre del item: ' + item.title);

        const index = parentItem?.children.findIndex((ch) => item.id === ch);
        if (index < 0) return;
        let move = 0;
        if (dir === 'down') {
            if (index + 1 >= parentItem?.children.length) return;
            move = 1;
        }
        if (dir === 'up') {
            if (index - 1 < 0) return;
            move = -1;
        }
        moveChild(item, index, index + move);
    };

    const onExpandBtnPressed = (
        list: Array<string | undefined>,
        event: { expanded: boolean; node: { key: string } },
    ) => {
        if (event.expanded) setExpanded([...expanded, event.node?.key]);
        else setExpanded(expanded.filter((e) => e === '0-0' || e !== event.node?.key));
    };

    const onExpandAllBtnPressed = () => {
        setExpanded(allKeys);
    };

    const onCollapseBtnPressed = () => {
        setExpanded(['0-0']);
    };

    const generateExpectedKeys = (id = 'root', key = '0-0', acc: Key[] = []) => {
        let item: ItemProviderStruct;
        acc = [...acc, key];
        if (id === 'root') item = treeControlItems['root'];
        else item = treeControlItems[findTreeId(id)];
        if (!!item && item.children?.length > 0)
            item.children?.forEach((element, i) => {
                acc = [...acc, ...generateExpectedKeys(element, key + '-' + i)];
            });
        return acc;
    };

    const sendAlert = (msg: string) => {
        messageApi.open({
            type: 'error',
            content: msg,
        });
    };

    /* ================== Private Methods ================== */

    // Return state and events
    return {
        treeProvider,
        treeControlItems,
        expanded,
        isLoadingTree,
        isModalAddVisible,
        isModalEditVisible,
        contextHolder,
        onAddChildBtnPressed,
        onEditChildBtnPressed,
        onRemoveChildBtnPressed,
        onMoveChildBtnPressed,
        onExpandBtnPressed,
        onExpandAllBtnPressed,
        onCollapseBtnPressed,
    };
};
