import React from 'react';
import 'components/alert-validate-user/alert-validate-user.scss';
import { Button, Row, Spin } from 'antd';
import { useMainSupportContext } from 'fragments/main-support/context/main-support.context';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AlertValidateUserProps {
    type: string;
    onChildrenBack: () => void;
}

const AlertValidateUser: React.FC<AlertValidateUserProps> = (props) => {
    const { emailUser } = useMainSupportContext();
    // State

    return (
        <div>
            {(() => {
                switch (props.type) {
                    case 'error':
                        return (
                            <div className="alert-error">
                                <span className="msj-error">
                                    El correo {emailUser} no se encuentra registrado como un usuario valido.
                                </span>
                                <span className="msj-error-body">
                                    <span className="title-alert">Usuario no registrado.</span>
                                    Para utilizar la aplicación debe registrarse desde la pantalla inicial de la
                                    aplicación.
                                </span>
                                <Button
                                    onClick={() => {
                                        props.onChildrenBack();
                                    }}
                                    className="btn-reingresar"
                                >
                                    Reingresar
                                </Button>
                            </div>
                        );

                    case 'success':
                        return (
                            <div className="alert-success">
                                <span className="msj-success">El correo {emailUser} ya ha sido usado.</span>
                                <span className="msj-success-body">
                                    <span className="title-alert">Usuario válido.</span>
                                    El correo {emailUser} es valido. Si olvido su contraseña debera realizar el proceso
                                    de recuperacion de contraseña desde la pantalla inicial de la aplicación!
                                </span>
                            </div>
                        );

                    default:
                        return (
                            <Row justify="center">
                                <Spin />
                            </Row>
                        );
                }
            })()}
        </div>
    );
};

export default AlertValidateUser;
