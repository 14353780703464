import React, { useState } from 'react';
import 'components/validate-user/validate-user.scss';
import { Button, Form, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { MainSupportController } from 'fragments/main-support/interfaces';
import validator from 'validator';
import ReCAPTCHA from 'react-google-recaptcha';
import { useAPICaptureSupport } from 'services/capture-support/api-capture-support.service';
import { useMainSupportContext } from 'fragments/main-support/context/main-support.context';

export interface ValidateUserProps {
    controller?: MainSupportController;
    id: string;
    onSelect: (id: string) => void;
}

const ValidateUser: React.FC<ValidateUserProps> = (props) => {
    const supportAPI = useAPICaptureSupport();
    const { setAlertType, setEmailUser } = useMainSupportContext();

    // State
    const [hiddenInputEmail, setHiddenInputEmail] = useState(false);
    const [user, setUser] = useState<string>('');
    const [emailError, setEmailError] = useState('');
    const [emailStatus, setEmailStatus] = useState<string>('');
    const [inputValid, setInputValid] = useState<boolean>(false);
    const [recaptchaToken, setRecaptchaToken] = useState<string>('');
    const [recaptchaValid, setRecaptchaValid] = useState<boolean>(false);

    function onReCaptchaChange(token: string) {
        //console.log('RECPATCH Value ' + token);
        setRecaptchaToken(token);
        setRecaptchaValid(true);
    }
    /*function onHiddenAll() {
        setHiddenInputEmail(true);
    } */

    function validateUser() {
        // endpoint para validar el usuario
        if (!inputValid) {
            setEmailStatus('warning');
            setEmailError('Debe ingresar su E-mail!');
            return;
        }
        props.onSelect(props.id);
        supportAPI
            .checkUser({ email: user, recaptcha: recaptchaToken })
            .then((response) => {
                switch (response.code) {
                    case 200:
                        setAlertType('success');
                        setEmailUser(user);
                        setHiddenInputEmail(true);
                        break;
                    case 202:
                        setAlertType('error');
                        setHiddenInputEmail(true);
                        break;
                    default:
                        setAlertType('error');
                        setHiddenInputEmail(true);
                        break;
                }
            })
            .catch((e) => {
                console.log('Error: check-user', e);
                setAlertType('error');
                setHiddenInputEmail(true);
            });
    }

    /* function validateUserError() {
        // endpoint para validar el usuario
        response = 400;
        if (!inputValid) {
            setEmailStatus('warning');
            setEmailError('Debe ingresar su E-mail!');
            return;
        }
        if (response === 200) {
            setHiddenInputEmail(true);
            sethiddenTicket(false);
        } else {
            setHiddenInputEmail(true);
        }
    } */

    /* function backInput() {
        setHiddenInputEmail(false);
    } */

    function validateEmail(e) {
        const email = e.target.value;
        setUser(email);
        if (validator.isEmail(email)) {
            setEmailError('Email valido');
            setEmailStatus('success');
            setInputValid(true);
        } else {
            setEmailError('Ingrese un email valido!');
            setEmailStatus('error');
            setInputValid(false);
        }
    }

    // onChange={event => setUser(event.target.value)}
    return (
        <div className={'validate-user'}>
            <div className="input-user" hidden={hiddenInputEmail}>
                <h2 className="title-input">Ingrese su correo</h2>
                <h4 className="label-email">E-mail</h4>
                <Form.Item
                    required
                    hasFeedback
                    validateStatus={(() => {
                        switch (emailStatus) {
                            case 'validating':
                                return 'validating';
                            case 'success':
                                return 'success';
                            case 'error':
                                return 'error';
                            case 'warning':
                                return 'warning';
                            default:
                                return '';
                        }
                    })()}
                    help={emailError}
                >
                    <Input
                        className="input-email"
                        size="large"
                        required={true}
                        placeholder="user@accefy.io"
                        onChange={(user) => validateEmail(user)}
                        prefix={<UserOutlined />}
                    />
                </Form.Item>
                <div className="captcha-user">
                    <ReCAPTCHA sitekey="6Lf9N4ogAAAAABNUw950XLiAK3pGdwsBzsjBAQTS" onChange={onReCaptchaChange} />
                </div>
                <Button
                    disabled={!inputValid || !recaptchaValid}
                    onClick={validateUser}
                    className="btn-validate"
                    type="primary"
                >
                    Verificar
                </Button>
            </div>
        </div>
    );
};

export default ValidateUser;
