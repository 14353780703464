import { ItemProviderStruct, useMainSupportContext } from '../../main-support/context/main-support.context';
import React, { useEffect, useState } from 'react';
import { SupportControlPanelContext } from './support-control-panel.context';
import { useAPICaptureSupport } from 'services/capture-support/api-capture-support.service';
import { CreateElementInput } from 'services/capture-support/capture-support.service';

type Props = {
    children?: React.ReactNode;
};

const defaultInput: CreateElementInput = {
    title: '',
    subtitle: '',
    type: 0,
    login: false,
    suggestions: [],
    showForm: false,
    showAlert: false,
    parent: '',
};
const defaultGroup: ItemProviderStruct = {
    id: '',
    title: 'Pregunta Ticket',
    subtitle: '',
    type: 8,
    login: false,
    suggestions: [],
    showForm: false,
    showAlert: false,
    parent: '',
    children: [],
};

const SupportControlPanelProvider: React.FC<Props> = (props) => {
    //const [searchParams, setSearchParams] = useSearchParams();
    //const loginParam = searchParams.get('login') || 'false';
    const supportAPI = useAPICaptureSupport();
    const { treeProvider, setIsLoadingTree, findTreeId, updateElements } = useMainSupportContext();

    const [parentId, setParentId] = useState<string>('');
    const [treeControlItems, setTreeControlItems] = useState<Record<string, ItemProviderStruct>>({});

    const [inputModalAdd, setInputModalAdd] = useState<CreateElementInput>(defaultInput);
    const [isModalAddVisible, setIsModalAddVisible] = useState<boolean>(false);
    const [isModalEditVisible, setIsModalEditVisible] = useState<boolean>(false);

    const [alertError, setAlertError] = useState<string>('');

    useEffect(() => {
        updateElements('all');
    }, []);

    useEffect(() => {
        if (!Object.values(treeProvider).length) return;
        setIsLoadingTree(true);
        const initialTree = providerToControl(treeProvider);
        if (!initialTree) return console.log('Error: convert Provider to Control');
        setTreeControlItems(initialTree);
        setIsLoadingTree(false);
    }, [treeProvider]);

    useEffect(() => {
        //console.log('treeControlItems: ', treeControlItems);
    }, [treeControlItems]);

    /* Public Methods */
    const createChild = (input: CreateElementInput) => {
        const isParentLogin = treeProvider[findTreeId(parentId)]?.login;
        setIsLoadingTree(true);
        if (treeProvider) {
            supportAPI
                .insertElement({
                    ...input,
                    login: isParentLogin,
                    subtitle: input.type === 2 ? 'PREGUNTA TICKET' : '',
                    parent: parentId,
                })
                .then(async (res) => {
                    const ticketNOId = res.msg;
                    if (input.type === 2) {
                        const childNO = await supportAPI.insertElement({
                            ...defaultInput,
                            type: 2,
                            login: isParentLogin,
                            subtitle: 'FORM',
                            showForm: true,
                            parent: ticketNOId,
                        });
                        if (childNO.code !== 200) {
                            setAlertError('Error on create ticket (child of NO): ' + res);
                        }
                    }
                    if (input.type === 3) {
                        const childAlert = await supportAPI.insertElement({
                            ...defaultInput,
                            type: 4,
                            login: isParentLogin,
                            subtitle: 'VALIDATION ALERT',
                            parent: ticketNOId,
                        });
                        if (childAlert.code !== 200) {
                            setAlertError('Error on create Alert (child of Validate User): ' + res);
                        }
                    }
                    updateElements();
                    setInputModalAdd(defaultInput);
                })
                .catch((err) => {
                    setAlertError('Error on create child: ' + err);
                })
                .finally(() => {
                    //setIsLoadingTree(false);
                });
            // IF IS TICKET
            if (input.type === 2) {
                setIsLoadingTree(true);
                supportAPI
                    .insertElement({
                        ...defaultInput,
                        subtitle: 'SUCCESS',
                        type: 5,
                        login: isParentLogin,
                        showAlert: true,
                        parent: parentId,
                    })
                    .then(async (res) => {
                        const ticketYESId = res.msg;
                        const childYES = await supportAPI.insertElement({
                            ...defaultInput,
                            type: 5,
                            login: isParentLogin,
                            subtitle: 'YES',
                            showForm: true,
                            parent: ticketYESId,
                        });
                        if (childYES.code !== 200) {
                            setAlertError('Error on create ticket (child of YES): ' + childYES);
                        }
                        //updateElements();
                    })
                    .catch((err) => {
                        setAlertError('Error on create ticket (YES): ' + err);
                    })
                    .finally(() => {
                        setIsLoadingTree(false);
                    });
            }
        }
    };
    const updateChild = () => {
        if (!treeProvider) return;
        setIsLoadingTree(true);
        const { title, type, suggestions } = inputModalAdd;
        supportAPI
            .modifyElement(parentId, { title, type, suggestions })
            .then(() => {
                updateElements();
            })
            .catch((err) => {
                setAlertError('Error on edit: ' + err);
            })
            .finally(() => {
                setIsLoadingTree(false);
            });
    };
    const removeChild = (id: string) => {
        if (!treeControlItems) return console.log('No se cargó el arbol todavia');
        const item = treeControlItems[findTreeId(id)];
        if (!item) return console.log('No se encontro el item a remover');
        const pId = findTreeId(item.parent);
        const newChildren = treeControlItems[pId].children.filter((ch) => id !== ch);

        const idsToDelete = getDescendIds(item);
        if (treeControlItems[pId].children.length === newChildren.length) return console.log('No se removió');
        setIsLoadingTree(true);
        Promise.all(idsToDelete.map((e) => supportAPI.removeElement(e)))
            .then(() => {
                updateElements();
            })
            .catch((err) => {
                setAlertError('Error on remove: ' + err);
            })
            .finally(() => {
                //setIsLoadingTree(false);
            });
    };
    const moveChild = (item: ItemProviderStruct, oldPos: number, newPos: number) => {
        const pId = findTreeId(item.parent);
        if (newPos === oldPos || newPos < 0 || newPos >= treeControlItems[pId].children.length) return;
        let newChildrenOrder = move(treeControlItems[pId].children, oldPos, newPos);
        if (treeControlItems[pId].children.length !== newChildrenOrder.length)
            return console.log(
                'ATENCION: Esta accion no se puede realizar ya que el codigo removió o agregó un elemento',
            );
        setTreeControlItems({
            ...treeControlItems,
            [pId]: { ...treeControlItems[pId], children: newChildrenOrder },
        });
        newChildrenOrder = newChildrenOrder.reduce((acc, e) => {
            // ungroup
            const chItem = treeControlItems[findTreeId(e)];
            if (chItem?.type === 8) return [...acc, ...chItem?.children];
            else return [...acc, e];
        }, []);
        setIsLoadingTree(true);
        supportAPI
            .modifyElement(item.parent, { children: newChildrenOrder })
            .then(() => {
                //updateElements(); No es necesario
            })
            .catch((err) => {
                setAlertError('Error on move: ' + err);
            })
            .finally(() => {
                setIsLoadingTree(false);
            });
    };
    const validate = () => {
        const parentItem = treeControlItems[findTreeId(parentId)];
        const errors: string[] = [];

        if (!parentItem) return ['No se encontro el padre para este nuevo item'];
        if (inputModalAdd.type === 2 && parentItem.children.some((e) => treeControlItems[findTreeId(e)]?.type === 8))
            errors.push('Ya tienes una "Pregunta Ticket" en este item, no puedes poner mas de una');
        return errors;
    };

    /* Private Methods */
    const getDescendIds = (item: ItemProviderStruct, addSelf = true) => {
        console.log({ item });
        let acc: string[] = [];
        if (addSelf && item.type !== 8) {
            acc.push(item.id);
        }
        if (!item.children.length) return acc;
        item.children.forEach((e) => {
            const child = treeControlItems[findTreeId(e)] || treeProvider[findTreeId(e)];
            if (child) acc = [...acc, ...getDescendIds(child)];
        });
        return acc;
    };
    const move = (arr, old_index, new_index) => {
        if (arr.length === 0) return [];
        if (old_index === new_index) return arr;
        const element = arr[old_index];

        if (new_index > old_index) {
            return arr.reduce((acc, e, i) => {
                if (i !== old_index) acc.push(e);
                if (i === new_index) acc.push(element);
                return acc;
            }, []);
        }
        if (new_index < old_index) {
            return arr.reduce((acc, e, i) => {
                if (i === new_index) acc.push(element);
                if (i !== old_index) acc.push(e);
                return acc;
            }, []);
        }
        return arr;
    };
    const groupTickets = (entries: [string, ItemProviderStruct][], tree: Record<string, ItemProviderStruct>) => {
        let nextIndex = 0;
        let newTree = {};
        const groupCollection: Record<string, ItemProviderStruct> = {};
        for (const e of entries) {
            if (e[1].type === 2 || e[1].type === 5) {
                const existingGroup = groupCollection[findTreeId(e[1].parent)];
                const groupCollector = existingGroup || { ...defaultGroup };
                const pItem = tree[findTreeId(e[1].parent)];
                const index =
                    pItem && pItem.type !== 2 && pItem.type !== 5 ? pItem.children.findIndex((x) => x === e[1].id) : -1;
                if (index >= 0) {
                    groupCollection[findTreeId(e[1].parent)] = {
                        ...groupCollector,
                        parent: e[1].parent,
                        id: groupCollector.id !== '' ? groupCollector.id : nextIndex.toString(),
                        subtitle: groupCollector.subtitle !== '' ? groupCollector.subtitle : index.toString(),
                        children: [...groupCollector.children, e[1].id],
                    };
                    !!existingGroup && nextIndex++;
                }
            } else {
                newTree = { ...newTree, [e[0]]: e[1] };
                continue;
            }
        }
        const groupEntries = Object.entries(groupCollection);
        for (const e of groupEntries) {
            const pos = Number(e[1].subtitle);
            if (pos < 0) continue;
            const newChildren = newTree[e[0]]?.children.reduce((acc, ch, i) => {
                const item = tree[findTreeId(ch)];
                if (item?.type !== 2 && item?.type !== 5) return [...acc, ch];
                if (pos === i) return [...acc, e[1].id];
                return acc;
            }, []);
            if (newTree[e[0]]) newTree[e[0]].children = newChildren;
            else console.log('Error en el armado de grupos');
            newTree['item' + e[1].id] = e[1];
        }
        return newTree;
    };
    const providerToControl = (tree: Record<string, ItemProviderStruct>) => {
        const entries = Object.entries(tree);
        const newTree = groupTickets(entries, tree);
        if (!Object.values(newTree).length) return false;

        return newTree;
    };

    return (
        <SupportControlPanelContext.Provider
            value={{
                treeControlItems,
                isModalAddVisible,
                isModalEditVisible,
                parentId,
                inputModalAdd,
                alertError,
                setInputModalAdd,
                setIsModalAddVisible,
                setIsModalEditVisible,
                removeChild,
                updateChild,
                moveChild,
                createChild,
                setParentId,
                validate,
                setAlertError,
            }}
        >
            {props.children}
        </SupportControlPanelContext.Provider>
    );
};

export default SupportControlPanelProvider;
