import React, { useEffect, useState } from 'react';
import 'components/new-ticket/new-ticket.scss';
import { Button, Form, Input, Image } from 'antd';
import { MainSupportController } from 'fragments/main-support/interfaces';
import validator from 'validator';
import { UserOutlined } from '@ant-design/icons';
import { useAPICaptureSupport } from 'services/capture-support/api-capture-support.service';
import { ItemProviderStruct, useMainSupportContext } from 'fragments/main-support/context/main-support.context';
import { useSearchParams } from 'react-router-dom';

export interface NewTicketProps {
    id: string;
    showForm: boolean;
    email: string;
    currentQuestion: ItemProviderStruct;
    useController?: () => MainSupportController;
    onSelect: (id: string) => void;
}

const NewTicket: React.FC<NewTicketProps> = (props) => {
    const { treeProvider, findTreeId, title, titlePath, emailUser } = useMainSupportContext();
    const [searchParams] = useSearchParams();
    const loginParam = searchParams.get('login') || 'false';

    // State
    const supportAPI = useAPICaptureSupport();
    const [bodyTicket, setBodyTicket] = useState('');
    const [loading, setLoading] = useState(false);
    const [hiddenQuestion, setHiddenQuestion] = useState(false);
    const [hiddenForm, setHiddenForm] = useState(true);
    const [hiddenResponse, setHiddenResponse] = useState(true);
    const [user, setUser] = useState<string>(emailUser);
    const [disableInput, setDisableInput] = useState<boolean | undefined>(false);

    const [inputValidEmail, setInputValidEmail] = useState<boolean>(false);
    const [inputErrorEmail, setInputErrorEmail] = useState('');
    const [inputStatus, setInputStatus] = useState<string>('');

    const [inputValid, setInputValid] = useState<boolean>(false);
    const [inputError, setInputError] = useState('');
    const [inputStatusEmail, setInputStatusEmail] = useState<string>('');

    const [urlImageResponse, setUrlImageResponse] = useState<string>('');
    const [titleResponse, setTitleResponse] = useState<string>('');
    const [titleResponseDesign, setTitleResponseDesign] = useState<string>('');
    const [bodyResponse, setBodyResponse] = useState<string>('');
    const [body2Response, setBody2Response] = useState<string>('');

    useEffect(() => {
        onShowForm();
    }, []);

    function onCreateTicket() {
        setLoading(true);
        if (!inputValidEmail) {
            setInputStatusEmail('warning');
            setLoading(false);
            setInputErrorEmail('Debe llenar el campo!');
            return;
        }
        if (!inputValid) {
            setInputStatus('warning');
            setLoading(false);
            setInputError('Debe llenar el campo!');
            return;
        }
        const additional_data = {
            login: loginParam,
            path: titlePath,
        };
        supportAPI
            .createTicket({
                email: user,
                title,
                content: bodyTicket,
                additional_data: JSON.stringify(additional_data),
            })
            .then(() => {
                setUrlImageResponse('logoSuccess.png');
                setTitleResponse('Consulta enviada con éxito!');
                setTitleResponseDesign('title-response-success');
                setBodyResponse(
                    'Nos comunicaremos cuanto antes, dentro de las próximas 72 hs hábiles recibirás nuestro contacto.',
                );
                setBody2Response('Tú caso lo seguiremos por el email que nos informaste.');
            })
            .catch((e) => {
                console.log('Error create-ticket: ', e);

                setUrlImageResponse('logoError.png');
                setTitleResponse('Ha ocurrido un error!');
                setTitleResponseDesign('title-response-error');
                setBodyResponse('Ha ocurrido un error al enviar la consulta, intente de nuevo más tarde');
                setBody2Response('');
            })
            .finally(() => {
                setLoading(false);
                setHiddenForm(true);
                setHiddenResponse(false);
            });
    }

    function onShowForm() {
        if (props.showForm) {
            setHiddenQuestion(true);
            setHiddenForm(false);
        }
        onDisabledInput();
    }

    function onSolutionYes() {
        props.onSelect(
            props.currentQuestion.children
                .map((id) => treeProvider[findTreeId(id)] || undefined)
                .find((ch) => ch?.type === 5)?.id || '',
        );
    }

    function onSolutionNo() {
        props.onSelect(props.id);
    }

    function onDisabledInput() {
        if (props.email === undefined) {
            return undefined;
        }
        if (props.email != '') {
            setDisableInput(true);
            setInputErrorEmail('');
            setInputStatusEmail('success');
            setInputValidEmail(true);
        } else setDisableInput(false);
    }

    function setValueEmail() {
        if (props.email === '') {
            return undefined;
        } else {
            return props.email;
        }
    }

    function setEmail(user) {
        const email = user.target.value;
        setUser(email);
        if (validator.isEmail(email)) {
            setInputErrorEmail('Email valido');
            setInputStatusEmail('success');
            setInputValidEmail(true);
        } else {
            setInputErrorEmail('Ingrese un email valido!');
            setInputStatusEmail('error');
            setInputValidEmail(false);
        }
    }

    function validateBodyTicket(e: string) {
        setBodyTicket(e);
        if (!validator.isEmpty(e)) {
            setInputError('');
            setInputStatus('success');
            setInputValid(true);
        } else {
            setInputError('Debe llenar el campo!');
            setLoading(false);
            setInputStatus('error');
            setInputValid(false);
        }
    }

    function validateStatus(input: string) {
        switch (input) {
            case 'validating':
                return 'validating';
            case 'success':
                return 'success';
            case 'error':
                return 'error';
            case 'warning':
                return 'warning';
            default:
                return '';
        }
    }

    return (
        <div>
            <div className="container-question-help" hidden={hiddenQuestion}>
                <div className="question-help">
                    <span className="msj-question-help">¿Te fue útil esta solución?</span>
                    <div className="buttons-help">
                        <Button onClick={onSolutionNo} className="btnAccefy btn-help">
                            No
                        </Button>
                        <Button onClick={onSolutionYes} className="btnAccefy btn-help">
                            Si
                        </Button>
                    </div>
                </div>
            </div>
            <div className="formTicket" hidden={hiddenForm}>
                {
                    <>
                        <Form
                            size="large"
                            layout="vertical"
                            // onSubmitCapture={controller.onCreateReport}
                            name="nest-messages"
                        >
                            <h2 className="title-ticket-input">¡Cuéntanos un poco más!</h2>
                            <h4 className="label-email">E-mail</h4>
                            <Form.Item
                                required
                                hasFeedback
                                validateStatus={validateStatus(inputStatusEmail)}
                                help={inputErrorEmail}
                            >
                                <Input
                                    className="input-email"
                                    size="large"
                                    required={true}
                                    placeholder="user@accefy.io"
                                    disabled={disableInput}
                                    value={setValueEmail()}
                                    onChange={(user) => setEmail(user)}
                                    prefix={<UserOutlined />}
                                />
                            </Form.Item>
                            <h4 className="label-email">Déjanos tu consulta</h4>
                            <Form.Item
                                required
                                hasFeedback
                                validateStatus={validateStatus(inputStatus)}
                                help={inputError}
                            >
                                <Input.TextArea
                                    placeholder="Cuéntanos más sobre tu problema. Mientras más detalles nos puedas brindar, podremos solucionarlo más rapidamente."
                                    autoSize={{ minRows: 6, maxRows: 14 }}
                                    className="textArea"
                                    onChange={(e) => validateBodyTicket(e.target.value)}
                                    showCount
                                    maxLength={256}
                                />
                            </Form.Item>
                            <Form.Item className="div-btn">
                                <Button
                                    className="btn-send"
                                    type="primary"
                                    loading={loading}
                                    onClick={() => onCreateTicket()}
                                >
                                    Enviar
                                </Button>
                            </Form.Item>
                            <Image className="image-alert" preview={false} src="fondo-ticket.png" />
                        </Form>
                    </>
                }
            </div>

            <div className="response-success" hidden={hiddenResponse}>
                <Image className="response-image" preview={false} src={urlImageResponse} />
                <h2 className={titleResponseDesign}>{titleResponse}</h2>
                <span className="body-response-success">{bodyResponse}</span>
                <span className="body-response-success">{body2Response}</span>
            </div>
        </div>
    );
};

export default NewTicket;
