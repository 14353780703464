import MainSupportProvider from 'fragments/main-support/context/main-support-provider.component';
import SupportControlPanelProvider from 'fragments/support-control-panel/context/support-control-panel-provider.component';
import { MainSupportFragment } from 'fragments/main-support/main-support.fragment';
import { SupportControlPanelFragment } from 'fragments/support-control-panel/support-control-panel.fragment';
import React from 'react';
import { Route, Routes } from 'react-router';

export const AppRoutes: React.FC<unknown> = () => {
    return (
        <div>
            <Routes>
                <Route
                    index
                    element={
                        <MainSupportProvider>
                            <MainSupportFragment />
                        </MainSupportProvider>
                    }
                />
                <Route
                    path={'/admin'}
                    element={
                        <MainSupportProvider>
                            <SupportControlPanelProvider>
                                <SupportControlPanelFragment />
                            </SupportControlPanelProvider>
                        </MainSupportProvider>
                    }
                />
                <Route path={'/404'} element={<h1>404: Page Not Found</h1>} />
            </Routes>
        </div>
    );
};
