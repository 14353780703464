import React, { useState } from 'react';
import { useAPICaptureSupport } from 'services/capture-support/api-capture-support.service';
import { ItemProviderStruct, MainSupportContext } from './main-support.context';

type Props = {
    children?: React.ReactNode;
};

const MainSupportProvider: React.FC<Props> = (props) => {
    const supportAPI = useAPICaptureSupport();

    const [path, setPath] = useState<string[]>([]);
    const [titlePath, setTitlePath] = useState<string | 'invalid route'>('');
    const [title, setTitle] = useState('');
    const [treeProvider, setTreeProvider] = useState({});
    const [isLoadingTree, setIsLoadingTree] = useState<boolean>(false);
    const [emailUser, setEmailUser] = useState('');
    const [alertType, setAlertType] = useState<string>('');

    /* Public Methods */
    const findTreeId = (id: string): string => {
        if (id === treeProvider['main0']?.id) return 'main0';
        if (id === treeProvider['main1']?.id) return 'main1';
        if (id === 'root') return 'root';
        return 'item' + id;
    };

    const updateElements = (login = 'all') => {
        switch (login) {
            case 'true':
                login = 'login';
                break;
            case 'false':
                login = 'nologin';
                break;
            default:
                login = 'all';
                break;
        }
        supportAPI
            .getAllElements(login)
            .then((response) => {
                const initialTree: Record<string, ItemProviderStruct> = buildTreeProvider(response);
                setTreeProvider(initialTree);
            })
            .catch((err) => {
                console.log('Error get all elements: ', err);
            });
    };

    /* Private Methods */

    const buildTreeProvider = (data): Record<string, ItemProviderStruct> => {
        const responseToTreeProvider = data.map(mapDbElementToItemTreeProvider).reduce((acc, e) => {
            if (e.parent === '') {
                return { ...acc, ['main' + (e.login ? '0' : '1')]: e };
            } else {
                return { ...acc, ['item' + e.id]: e };
            }
        }, {});
        if (responseToTreeProvider['main0'] && responseToTreeProvider['main1']) {
            responseToTreeProvider['main0'].parent = 'root';
            responseToTreeProvider['main1'].parent = 'root';
            responseToTreeProvider['root'] = {
                id: 'root',
                title: 'ROOT',
                subtitle: '',
                type: 10,
                login: false,
                suggestions: [],
                showForm: false,
                showAlert: false,
                parent: '',
                children: [responseToTreeProvider['main0'].id, responseToTreeProvider['main1'].id],
            };
        }
        return responseToTreeProvider;
    };

    const mapDbElementToItemTreeProvider = (e): ItemProviderStruct => {
        return {
            id: e._id,
            title: e.title,
            subtitle: e.subtitle,
            type: e.type,
            login: e.login,
            suggestions: e.suggestions,
            showForm: e.showForm,
            showAlert: e.showAlert,
            parent: e.parent,
            children: e.children,
        };
    };

    return (
        <MainSupportContext.Provider
            value={{
                treeProvider,
                setTreeProvider,
                path,
                setPath,
                setTitlePath,
                setTitle,
                title,
                titlePath,
                isLoadingTree,
                setIsLoadingTree,
                emailUser,
                setEmailUser,
                alertType,
                setAlertType,
                findTreeId,
                updateElements,
            }}
        >
            {props.children}
        </MainSupportContext.Provider>
    );
};

export default MainSupportProvider;
